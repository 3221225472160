import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import Image from "reusecore/src/elements/Image";
import Container from "common/src/components/UI/Container";
import ProcessItem, { ProcessIndex } from "./workingProcess.style";

const WorkingProcessSection = ({
  sectionWrapper,
  secTitleWrapper,
  secText,
  secHeading,
  processRow,
  processCol,
  processImageStyle,
  processTitleStyle,
  processDescriptionStyle
}) => {
  const Data = useStaticQuery(graphql`
    query {
      saasModernJson {
        PROCESS_ITEMS {
          title
          description
          image {
            publicURL
          }
        }
      }
    }
  `);

  return (
    <Box {...sectionWrapper} as="section" id="how_section">
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content="HOW IT WORKS" />
          <Heading {...secHeading} content="How we analyze your calendar" />
        </Box>

        <Box {...processRow}>
          {Data.saasModernJson.PROCESS_ITEMS.map((item, index) => (
            <Box
              {...processCol}
              key={`process-item-${index}`}
              className="process_item_col"
            >
              <ProcessItem className="process_item">
                <ProcessIndex>{item.index || index + 1}</ProcessIndex>
                <Image
                  src={item.image.publicURL}
                  alt={`process-image-${index + 1}`}
                  {...processImageStyle}
                  style={{ width: 150 }}
                />
                <Heading as="h3" content={item.title} {...processTitleStyle} />
                <Text content={item.description} {...processDescriptionStyle} />
              </ProcessItem>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

WorkingProcessSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  processRow: PropTypes.object,
  processCol: PropTypes.object,
  processImageStyle: PropTypes.object,
  processTitleStyle: PropTypes.object,
  processDescriptionStyle: PropTypes.object
};

WorkingProcessSection.defaultProps = {
  sectionWrapper: {
    pt: ["60px", "15px", "15px", "15px", "15px"],
    pb: 0
  },
  secTitleWrapper: {
    mb: ["60px", "100px"]
  },
  secText: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "14px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#2aa275",
    mb: "5px"
  },
  secHeading: {
    textAlign: "center",
    fontSize: ["20px", "24px"],
    fontWeight: "500",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "0",
    lineHeight: "1.67"
  },
  processRow: {
    flexBox: true,
    flexWrap: "wrap",
    ml: ["0", "-15px", "-20px", "-35px", "-45px"],
    mr: ["0", "-15px", "-20px", "-35px", "-45px"]
  },
  processCol: {
    width: [1, 1 / 3],
    pl: ["0", "15px", "20px", "35px", "45px"],
    pr: ["0", "15px", "20px", "35px", "45px"],
    mb: "40px"
  },
  processImageStyle: {
    ml: ["auto", "0", "0", "0"],
    mr: ["auto", "0", "0", "0"],
    mb: "35px",
    width: ["60px", "60px", "70px", "auto"]
  },
  processTitleStyle: {
    fontSize: ["20px", "18px", "20px", "20px", "20px"],
    fontWeight: "400",
    color: "#0f2137",
    letterSpacing: "-0.02em",
    mb: ["20px", "20px", "22px", "22px", "22px"]
  },
  processDescriptionStyle: {
    fontSize: ["15px", "15px", "15px", "15px"],
    fontWeight: "400",
    color: "#343d48",
    lineHeight: "1.87"
  }
};

export default WorkingProcessSection;
